import {
  AppBar,
  Toolbar,
  makeStyles,
  Button,
  IconButton,
  Drawer,
  Grid,
  MenuItem,
  Box,
  Container,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import Logo from "./../../component/Logo";
import { CgClose } from "react-icons/cg";
import { AiOutlineTwitter } from "react-icons/ai";
import { AiOutlineInstagram } from "react-icons/ai";
import { FaDiscord } from "react-icons/fa";
import Scroll from "react-scroll";

const ScrollLink = Scroll.Link;

const useStyles = makeStyles((theme) => ({
  menuButton: {
    fontSize: "15px",
    fontStyle: "italic",
    lineHeight: "24px",
    fontWeight: "400",
    borderRadius: 0,
    minWidth: "100%",
    color: "#CAB483",
    fontFamily: "'Poppins', sans-serif",
    textTransform: "uppercase",
    textDecoration: "none",
    textAlign: "center",
    height: "30px",
    letterSpacing: "1px",
    // margin: "0 7px",
    [theme.breakpoints.down("md")]: {
      justifyContent: "center",
    },
    "@media (max-width: 900px)": {
      fontStyle: "normal",
      letterSpacing: "-0.6px",
      lineHeight: "24px",
      color: "#FFF",
      padding: "5px !important",
      // height: "51px",
      width: "100%",
      display: "flex",
      justifyContent: "flex-start",
      // alignItems: "flex-start",
    },
    "&.active": {
      color: "#cab483",
    },
    "&:hover": {
      color: "#000",
      backgroundColor: "#FFFFFF",
    },
  },
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
    height: "100%",
    padding:"0",
    "@media (max-width: 900px)": {
      paddingLeft: "75px",
      paddingRight: "20px",
      height: "100%",
    },
  },

  logoDrawer: {
    paddingLeft: "10px",
    width: "80px",
    marginBottom: "0px",
  },
  drawerContainer: {
    padding: "20px 0px ",
    height: "100%",
    background: "#000",
    width: "260px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
  },
  drawericon: {
    color: "#000",
    position: "absolute",
    top: "6px",
    right: "-10px",
    fontSize: "25px",
  },
  logoImg: {
    width: "75px",
    margin: " 14px 15px 11px 0px",
    objectFit: "contain",
    "@media (max-width: 500px)": {
      margin: " 11px 1px 3px 0px",
      width: "52px",
    },
  },

  containerHeight: {
    height: "100%",
  },
  mainHeader: {
    justifyContent: "space-between",
    padding: "0px",
  },
  hideMob: {
    display: "flex",
    justifyContent: "center",
    "@media (max-width: 1279px)": {
      display: "none",
    },
  },
  menuulicon: {
    listStyle: "none",
    padding: "0",
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    flexDirection: "row",
    "& a": {
      width: "30px",
      height: "30px",
      color: "#fff",
      margin: "0 28px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      fontSize: "20px",
      cursor: "pointer",
      padding: "0",
      [theme.breakpoints.down("xs")]: {
        margin: "0 10px",
      },
      "&:hover": {
        backgroundColor: "rgb(202, 180, 131)",
        color: "#fff",
      },
    },
  },
  menuul: {
    display: "flex",
    listStyle: "none",
    padding: "0",
    justifyContent: "space-between",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      display: "block",
      marginTop: "-20px",
    },
    "& button": {
      margin: "0",
      minWidth: "100%",
      "&:hover": {
        backgroundColor: "#ffffff",
      },
    },
  },
  menuli: {
    width: "23%",
    borderLeft: "2px solid #CAB483",
    // borderRight: "2px solid #CAB483",
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.down("md")]: {
      width: "100%",
      display: "flex",
      justifyContent: "flex-start",
      borderLeft: "2px solid #000",
      borderRight: "2px solid #000",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      borderLeft: "2px solid #000",
      borderRight: "2px solid #000",
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      borderLeft: "2px solid #000",
      borderRight: "2px solid #000",
    },
  },
  menulil: {
    width: "23%",
    borderLeft: "2px solid #CAB483",
    borderRight: "2px solid #CAB483",
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.down("md")]: {
      width: "100%",
      display: "flex",
      justifyContent: "center",
      borderLeft: "2px solid #000",
      borderRight: "2px solid #000",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      borderLeft: "2px solid #000",
      borderRight: "2px solid #000",
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      borderLeft: "2px solid #000",
      borderRight: "2px solid #000",
    },
  },
}));

export default function Header({ buttonClick }) {
  const {
    menuButton,
    toolbar,
    menuul,
    menuulicon,
    menuli,
    menulil,
    hideMob,
    drawerContainer,
    drawericon,
    logoDrawer,
    containerHeight,
    mainHeader,
  } = useStyles();
  const history = useHistory();
  console.log(history.location);

  const [state, setState] = useState({
    mobileView: false,
    drawerOpen: false,
  });

  const { mobileView, drawerOpen } = state;

  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 1220
        ? setState((prevState) => ({ ...prevState, mobileView: true }))
        : setState((prevState) => ({ ...prevState, mobileView: false }));
    };
    setResponsiveness();
    window.addEventListener("resize", () => setResponsiveness());
  }, []);

  const displayDesktop = () => {
    return (
      // <Container maxWidth="lg" className="p-0">
      <Toolbar className={toolbar}>
        <Grid
          container
          direction="row"
          justify="flex-end"
          alignItems="center"
          style={{ paddingLeft: "0px" }}
        >
          {/* <Grid item xs={2}>
            {productLogo}
          </Grid> */}
          <Grid item xs={12} align="right">
            {menuText}
          </Grid>
        </Grid>
      </Toolbar>
      // </Container>
    );
  };

  const displayMobile = () => {
    const handleDrawerOpen = () =>
      setState((prevState) => ({ ...prevState, drawerOpen: true }));
    const handleDrawerClose = () =>
      setState((prevState) => ({ ...prevState, drawerOpen: false }));

    return (
      <Toolbar className={mainHeader}>
        <Drawer
          {...{
            anchor: "right",
            open: drawerOpen,
            onClose: handleDrawerClose,
          }}
        >
          <Box
            style={{
              display: "flex",
              flexDirection: "row",
              backgroundColor: "black",
            }}
          >
            <Box style={{ display: "flex", flexDirection: "column" }}>
              <Box>{productLogo1}</Box>
              {/* <Box style={{ display: "flex" }}>
                <Link to="/" style={{ padding: "10px" }}>
                  <Logo className="logoImg" width="100px" />
                </Link>
              </Box> */}
              <Box>
                <ul className={menuulicon}>
                  <li>
                    <div>
                      <a
                        href="https://discord.com/invite/E3FtQXM48h"
                        target="_blank"
                        style={{}}
                      >
                        <FaDiscord />
                      </a>
                    </div>
                  </li>
                  <li>
                    {" "}
                    <div>
                      <a
                        href="https://twitter.com/DoggiePunks_"
                        target="_blank"
                        style={{}}
                      >
                        <AiOutlineTwitter />
                      </a>
                    </div>
                  </li>
                  <li>
                    <div>
                      <a
                        href="https://www.instagram.com/doggiepunks/"
                        target="_blank"
                      >
                        <AiOutlineInstagram />
                      </a>
                    </div>
                  </li>
                </ul>
              </Box>
            </Box>
            <IconButton onClick={handleDrawerClose} className="closeIcons">
              <CgClose width={35} style={{ color: "#42e8e0" }} />
            </IconButton>
          </Box>
          <div className={drawerContainer}>
            <img className={logoDrawer} src="images/logo.svg" alt="" />
            {menuText}
          </div>
        </Drawer>

        <div>{productLogo}</div>

        <Grid container>
          <Grid item xs={10}></Grid>
          <Grid item xs={2}>
            <IconButton
              className={drawericon}
              {...{
                edge: "start",
                color: "inherit",
                "aria-label": "menu",
                "aria-haspopup": "true",
                onClick: handleDrawerOpen,
              }}
            >
              <MenuIcon
                width="60px"
                height="60px"
                style={{ color: "#fff", fontSize: "30px" }}
              />
            </IconButton>
          </Grid>
        </Grid>
      </Toolbar>
    );
  };

  const productLogo = (
    <Box style={{ textAlign: "left" }}>
      <Link to="/" style={{ padding: "10px" ,  }}>
        <Logo className="logoImg" />
      </Link>
    </Box>
  );
  const productLogo1 = (
    <Box style={{ textAlign: "center", width: "150px" }}>
      <Link to="/" style={{ padding: "10px" }}>
        <Logo className="logoImg1" />
      </Link>
    </Box>
  );

  const menuText = (
    <nav>
      <ul class={menuul}>

        <li to="/" className={hideMob}>
    
          {" "}
          {productLogo}
         
         
        </li>
        <li class={menuli}>
          {history.location.pathname !== "/about" ? (
            <Link component={Button} className={menuButton} to="/about">
              About
            </Link>
          ) : (
            <ScrollLink
              className={menuButton}
              smooth={true}
              duration={500}
              to="section1"
              component={Button}
            >
              About
            </ScrollLink>
          )}
        </li>

        {/* <li class={menuli}>
          {" "}
          <Button component={Link} to="/about" className={menuButton}>
            About
          </Button>
        </li> */}
        <li class={menuli}>
          {" "}
          <Button component={Link} to="/roadmap" className={menuButton}>
            Roadmap & Rewards
          </Button>
          {/* <MenuItem className={menuButton}>Roadmap</MenuItem> */}
        </li>
        <li class={menuli}>
          {history.location.pathname !== "/about" ? (
            <Link component={Button} className={menuButton} to="/about">
              Specs
            </Link>
          ) : (
            <ScrollLink
              className={menuButton}
              smooth={true}
              duration={500}
              to="section2"
              component={Button}
            >
              Specs
            </ScrollLink>
          )}
        </li>
        {/* <li class={menuli}>
          {" "}
          <Button component={Link} to="/#" className={menuButton}>
            Specs
          </Button>
         
        </li> */}
        <li class={menulil}>
          {" "}
          <Button to="/#" className={menuButton}>
            Gallery
          </Button>
        </li>
      </ul>
    </nav>
  );

  return (
    <>
      <AppBar
        // position={history.location.pathname !== "/" ? "relative" : "absolute"}
        className={history.location.pathname !== "/" ? "InnerHeader" : "Header"}
        elevation={0}
        style={{
          backgroundColor: "#000",
          borderBottom: "5px solid #CAB483",
        }}
      >
        <Container
          maxWidth={history.location.pathname !== "/" ? "lg" : "lg"}
          className={containerHeight}
        >
          {mobileView ? displayMobile() : displayDesktop()}
        </Container>
      </AppBar>
    </>
  );
}
