import _ from "lodash";
import { colors, createMuiTheme, responsiveFontSizes } from "@material-ui/core";
import typography from "./typography";

const baseOptions = {
  typography,
  overrides: {



    MuiList: {
      padding: {
        paddingTop: "8px",
        paddingBottom: "8px",
        padding: "10px",
      },

    },
    MuiFormLabel: {
      root: { color: "#222" },
      colorSecondary: {
        "&.Mui-focused": {
          color: "#222",
        },
      },
    },
    MuiListSubheader: {
      root: {
        color: "#000000",
        fontSize: "22px !important",
        fontWeight: "600 !important",
        lineHeight: "33px !important",
      },
    },
    MuiIconButton: {
      root: {
        flex: "0 0 auto",
        color: "rgba(0, 0, 0, 0.54)",
        padding: "50px",
        overflow: "visible",
        fontSize: "1.5rem",
        textAlign: "center",
        transition: "background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        borderRadius: "50%"
      }
    },
    MuiOutlinedInput: {
      root: {
        position: "relative",
        borderRadius: "4px",
        marginTop: "7px",

      },
      colorSecondary: {
        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
          color: "#222",
          borderColor: "#222",
        },
        "&.Mui-focused": {
          color: "#222",
        },
      },
    },
    MuiIconButton: {
      label: {
        color: "#CAB483",
        minWidth: "100%",
        display: "flex",
        alignItems: "inherit",
        justifyContent: "end",
        "&:hover": {
          backgroundColor: "red",
        },
      },
    },
    MuiPaper: {
      root: {
        color: "#52565c",
        backgroundColor: "#ebebeb",

      },
      outlined: {
        padding: "20px",
        width: "100%",
      },
    },
    MuiPopover: {
      root: {
        zIndex: 99999,
      },
    },
    MuiListItem: {
      root: {
        alignItems: "self-start",
      },
      gutters: {
        paddingLeft: 0,
      },
    },
    MuiCheckbox: {
      root: {
        padding: "4px",
        fontSize: "12px",
      },
      colorSecondary: {
        "&.Mui-checked": { color: "#000" },
      },
    },
    MuiFormControlLabel: {
      root: {
        paddingBottom: "0",
      },
    },
    MuiListItemSecondaryAction: {
      root: {
        right: 0,
      },
    },
    MuiDialog: {
      paperScrollPaper: {
        Width: 450,
        maxWidth: "100%",
      },
      paper: {
        overflowY: "unset",
      },
      paperWidthSm: {
        maxWidth: "900px !important",
      },
    },
    MuiInputBase: {
      input: {
        fontSize: 14,
        color: "#222",
        height: "0.1876em",
      },
    },
    MuiBackdrop: {
      root: { backgroundColor: "rgba(0, 0, 0, 0.75)" },
    },
    MuiButton: {
      root: {
        "&.Mui-disabled": {
          color: "#CAB483",
          "&:hover": {
            color: "#000",
            backgroundColor: "#FFFFFF",
          },
        },
      },
      containedSecondary: {
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
        borderRadius: "0px",
        color: "#000",
        fontSize: "18px",
        backgroundColor: "#CAB483",
        padding: "2% 10%",
        fontStyle: "italic",
        "&:hover": {
          backgroundColor: "#38761D",
          color: "#000",
        },
      },

      containedPrimary: {
        backgroundColor: "#38761D",
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
        borderRadius: "0px",
        color: "#CAB483",
        fontSize: "18px",
        //height: "40px",
        fontStyle: "italic",
        lineHeight: " 21px",
        padding: "5% 10%",
        // marginRight: "10px",
        "&:hover": {
          backgroundColor: "#000",
          color: "#38761D",
        },
      },

      containedSizeLarge: {
        padding: "16px 45px",
        fontSize: "18px",
        lineHeight: " 24px",
        letterSpacing: "2px",
        color: "#000",
        fontStyle: "italic",
      },

      contained: {
        backgroundColor: "#CAB483",
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
        borderRadius: "0px",
        color: "#000",
        textTransformation: "capitalized",
        fontWeight: 700,
        fontSize: "18px",
        letterSpacing: "2px",
        padding: "2% 9%",
        fontStyle: "italic",
        "&:hover": {
          backgroundColor: "#38761D",
          color: "#000",
        },
      },
      outlinedPrimary: {
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
        borderRadius: "10px",
        backgroundColor: "#CAB483",

        color: "#FFFFFF",
        fontWeight: 600,
        wordSpacing: "5px",
        padding: "20% 150%",
        // border: "2px solid #300760",
        "&:hover": {
          backgroundColor: "#38761D",
          color: "#000",
        },
      },
      outlinedSizeSmall: {
        padding: "6px 23px",
        fontSize: "16px",
        lineHeight: " 24px",
      },
    },
    MuiDrawer: {
      paperAnchorDockedLeft: {
        borderRight: "0",
      },
    },
    MuiMenu: {
      paper: { top: "47px" },
    },

    MuiTypography: {
      subtitle1: {
        color: "#000",
        fontSize: "14px",
        fontWeight: 500,
        lineHeight: " 16px",
        colorSecondary: {
          color: "#8d8989",
        },
      },
    },
  },
};

const themesOptions = {
  typography: {
    fontWeight: 400,
    fontFamily: "'Poppins', sans-serif",
  },
  palette: {
    type: "light",
    action: {
      primary: "#20509e",
    },
    background: {
      default: "#FBFBFD",
      dark: "#f3f7f9",
      paper: colors.common.white,
    },
    primary: {
      main: "#898989",
      dark: "#de0d0d",
      light: "#de0d0d",
    },
    secondary: {
      main: "#fff",
    },
    warning: {
      main: "#ffae33",
      dark: "#ffae33",
      light: "#fff1dc",
    },
    success: {
      main: "#54e18c",
      dark: "#54e18c",
      light: "#e2faec",
    },
    error: {
      main: "#ff7d68",
      dark: "#ff7d68",
      light: "#ffe9e6",
    },
    text: {
      primary: "#52565c",
      secondary: "#999999",
    },
    common: {
      black: "#222222",
    },
  },
};

export const createTheme = (config = {}) => {
  let theme = createMuiTheme(_.merge({}, baseOptions, themesOptions));

  if (config.responsiveFontSizes) {
    theme = responsiveFontSizes(theme);
  }

  return theme;
};
