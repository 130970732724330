import React from "react";
import { Box, Container, makeStyles, Grid, Button, Typography } from "@material-ui/core";
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { AiOutlineTwitter } from "react-icons/ai";
import { AiOutlineReddit } from "react-icons/ai";
import { ImYoutube } from "react-icons/im";
import { BsYoutube } from "react-icons/bs";
import { AiOutlineInstagram } from "react-icons/ai";
import IconButton from '@material-ui/core/IconButton';
import DirectionsIcon from '@material-ui/icons/Directions';
import { Link } from "react-router-dom";
import TextField from "@material-ui/core/TextField";
import { FaDiscord } from "react-icons/fa";
import clsx from 'clsx';
import Input from '@material-ui/core/Input';
import FilledInput from '@material-ui/core/FilledInput';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import SaveIcon from '@material-ui/icons/Save';
import Icon from '@material-ui/core/Icon';

const useStyles = makeStyles((theme) => ({
  footerSection: {
    position: "relative",
    backgroundColor: "#000",
    borderTop: "1px solid #cab483",
    padding: "60px 60px",

    [theme.breakpoints.up("md")]: {
      paddingTop: theme.spacing(4),
    },
    [theme.breakpoints.up("sm")]: {
      paddingTop: theme.spacing(4),
    },
    [theme.breakpoints.up("xs")]: {
      paddingTop: theme.spacing(4),
    },
    "& p": {
      fontSize: "12px",
      margin: "0",
      padding: "2px 0",
      color:"#fff",
    },
    "& a": {
      fontSize: "16px",
      margin: "0",
      cursor: "pointer",
      padding: "10px 0",
      textDecoration: "none",
      color: "#989797",
      "&:hover": {
        textDecoration: "none",
      },
    },
  },
  menuul: {
    display: "flex",
    listStyle: "none",
    padding: "0",
    alignItems: "center",
    justifyContent: "center",
    "& a": {
      width: "30px",
      height: "30px",
      color: "#fff",
      margin: "0 10px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      fontSize: "20px",
      cursor: "pointer",
      padding: "0",
      "&:hover": {
        backgroundColor: "rgb(202, 180, 131)",
        color: "#fff",
      },
    },
  },
}));

export default function Liquidity() {
  const classes = useStyles();
  const [values, setValues] = React.useState({
    amount: '',
    password: '',
    weight: '',
    weightRange: '',
    showPassword: false,
  });
  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  return (
    <Box className={classes.footerSection}>
      <Container maxWidth="lg" align="center">


      <Grid container spacing={1} alignItems="center">
      <Grid item xs={12} sm={12} md={12} lg={4}>
       
      </Grid>

      <Grid item xs={12} sm={12} md={12} lg={4}>
      <Box className={classes.bannerText}>
      <Link to="/">
          <img src="images/footer_logo.png" style={{ width: "63%" }} alt="Logo" 
       />
       </Link>
        </Box>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={4}>
        <Box className={classes.bannerText}>
        <ul className={classes.menuul}>
        {/* <li>
            <div>
              <a
                href=" https://www.youtube.com/channel/UCLIhq1B9PjXD74-6f54UgLw"
                target="_blank"
                style={{ backgroundColor: "#fff", color: "red" }}
              >
                <BsYoutube />
              </a>
            </div>
          </li> */}
          {/* <li>
            <div>
              <a
                href=" https://www.reddit.com/r/Crypto_HoldemNFT/"
                target="_blank"
                style={{ backgroundColor: "green", color: "#fff" }}
              >
                <AiOutlineReddit />
              </a>
            </div>
          </li> */}
          <li>
            <div>
              <a
                href="https://discord.com/invite/E3FtQXM48h"
                target="_blank"
                style={{ }}
              >
                <FaDiscord />
              </a>
            </div>
          </li>
          <li>
            {" "}
            <div>
              <a
                href="https://twitter.com/DoggiePunks_"
                target="_blank"
                style={{  }}
              >
                <AiOutlineTwitter />
              </a>
            </div>
          </li>
          <li>
            <div>
              <a
                href="https://www.instagram.com/doggiepunks/"
                target="_blank"
              >
                <AiOutlineInstagram />
              </a>
            </div>
          </li>
        </ul>
       
        <Typography variant="body2">© 2022 Top Labs LLC</Typography>
       <Link to="/terms"> <Typography variant="body2" style={{color: "#cab483",}}>DoggiePunks Terms & Conditions</Typography></Link>
      
        </Box>
      </Grid>
     
    </Grid>
        {/* <img src="images/logo.png" style={{ width: "200px" }} alt="Logo" 
       />
        <ul className={classes.menuul}>
          <li>
            <div>
              <a
                href=" https://discord.gg/8pkAdzPcCA"
                target="_blank"
                style={{ backgroundColor: "#3f1178", color: "#fff" }}
              >
                <FaDiscord />
              </a>
            </div>
          </li>
          <li>
            {" "}
            <div>
              <a
                href="https://twitter.com/cryptoholdemnft"
                target="_blank"
                style={{ backgroundColor: "#3cdfff", color: "#fff" }}
              >
                <AiOutlineTwitter />
              </a>
            </div>
          </li>
          <li>
            <div>
              <a
                href="https://instagram.com/cryptoholdemnft?utm_medium=copy_link"
                target="_blank"
              >
                <AiOutlineInstagram />
              </a>
            </div>
          </li>
        </ul>
        <Link to="/terms">Terms of Service</Link>
        <p>© 2021 Crypto Holdem. All rights reserved</p> */}
      </Container>
    </Box>
  );
}
